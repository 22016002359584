import './style.css'
import './jquery.min.js'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import {gsap} from 'gsap'

var intersects

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Overlay
 */

 const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
 const overlayMaterial = new THREE.ShaderMaterial({
    transparent: true,
    uniforms:
    {
        uAlpha: { value: 1}
    },
    vertexShader: `
        void main()
        {
            gl_Position =  vec4(position, 1.0);
        }
    `,
    fragmentShader: `
    uniform float uAlpha;
        void main()
        {
            gl_FragColor = vec4(0.671,0.859,0.89, uAlpha);
        }
    `
})
 const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
 scene.add(overlay)

 /**
 * Loaders
 */

const loadingBarElement = document.querySelector('.loading-bar')
const loadingTextElement = document.querySelector('.loading-text')

const loadingManager = new THREE.LoadingManager(
    // Loaded
    () =>
    {
        window.setTimeout(() =>
        {
            gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 })
            loadingBarElement.classList.add('ended')
            loadingTextElement.style.display = "none";
            loadingBarElement.style.transform = ''
        }, 500)
    },

    // Progress
    (itemUrl, itemsLoaded, itemsTotal) =>
    {
        const progressRatio = itemsLoaded / itemsTotal
        loadingBarElement.style.transform = `scaleX(${progressRatio})`
        console.log(progressRatio)
    }
)

// Texture loader
const textureLoader = new THREE.TextureLoader(loadingManager)

// Draco loader
const dracoLoader = new DRACOLoader(loadingManager)
dracoLoader.setDecoderPath('draco/')

// GLTF loader
const gltfLoader = new GLTFLoader(loadingManager)
gltfLoader.setDRACOLoader(dracoLoader)

/**
 * Texture
 */
const bakedTexture = textureLoader.load('9.3ProfileBaked.jpg')
bakedTexture.flipY = false
const bakedProfileTexture = textureLoader.load('PicStaincil9.3.jpg')
bakedProfileTexture.flipY = false

/**
 * Material
 */

//baked material
const bakedMaterial = new THREE.MeshBasicMaterial({map: bakedTexture})
const bakedProfile = new THREE.MeshBasicMaterial ({map: bakedProfileTexture})

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Mouse
 */
 const mouse = new THREE.Vector2()

 window.addEventListener('mousemove', (event) =>
 {
     mouse.x = event.clientX / sizes.width * 2 - 1
     mouse.y = - (event.clientY / sizes.height) * 2 + 1 
 })

 
window.addEventListener('click', () =>
{
    if(currentIntersect)
    {
        switch(currentIntersect.object)
        {

            case objectToTest[0]:
                window.open('https://www.instagram.com/aiwith3d/','_blank');
                break

            case objectToTest[1]:
                window.open('https://www.linkedin.com/in/ai-hubrel/','_blank');
                break
            
            case objectToTest[2]:
                window.open('index.html','_blank');
                break

            case objectToTest[3]:
                window.open('https://aihubrel.com/game/WebGL/', '_blank');
                break

            case objectToTest[4]:
                window.open('https://www.aihubrel.com/gallery.html','_blank');
                break

            case objectToTest[5]:
                window.open('https://aihubrel.com/JavaProject/index.html', '_blank');
                break

            case objectToTest[6]:
               window.open('https://aihubrel.com/JSProject/JSindex.html','_blank');
                break    
            
            case objectToTest[7]:
                window.open('http://www.eonet.ne.jp/~eirokuenn/english/index.en.html','_blank');
                break            
        }
    }
})


/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(70, sizes.width / sizes.height, 0.1, 50)
camera.position.x = 0
camera.position.y = 5
camera.position.z = 3
scene.add(camera)

/**
 * Raycaster
 */

 const raycaster = new THREE.Raycaster()
 let currentIntersect = null
 //const rayOrigin = new THREE.Vector3(- 3, 0, 0)
 const rayDirection = new THREE.Vector3(10, 0, 0)
 rayDirection.normalize()
 
/**
 * Model
 */

var objectToTest = []
gltfLoader.load(
    'profile.9.3.Final.glb',
    (glft)=>
    {
        glft.scene.traverse((child)=>
        {
            child.material = bakedMaterial
        })
        scene.add(glft.scene)
        console.log(glft.scene)

        const profileMesh = glft.scene.children.find((child) => child.name === 'Profile')
        const instaMesh = glft.scene.children.find((child) => child.name === 'Instagram001')
        const linkedInMesh = glft.scene.children.find((child) => child.name === 'LinkedIn')
        const homeMesh = glft.scene.children.find((child) => child.name === 'Home')
        const gameMesh = glft.scene.children.find((child) => child.name === 'Game')
        const threeDMesh = glft.scene.children.find((child) => child.name === '3D')
        const javaMesh = glft.scene.children.find((child) => child.name === 'Java')
        const jsMesh = glft.scene.children.find((child) => child.name === 'JS')
        const webDevMesh = glft.scene.children.find((child) => child.name === 'WebDev')
            
        profileMesh.material = bakedProfile 
        
        objectToTest[0] = instaMesh
        objectToTest[1] = linkedInMesh
        objectToTest[2] = homeMesh
        objectToTest[3] = gameMesh
        objectToTest[4] = threeDMesh
        objectToTest[5] = javaMesh
        objectToTest[6] = jsMesh
        objectToTest[7] = webDevMesh
    }    
)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.maxPolarAngle =  Math.PI / 20
controls.minAzimuthAngle = - Math.PI / 360
controls.maxAzimuthAngle = Math.PI / 360
controls.maxDistance = 7
controls.minDistance = 3
controls.enablePan = false;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha:true,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
 const clock = new THREE.Clock()
 const tick = () =>
 {

     // Update controls
     controls.update()

     raycaster.setFromCamera(mouse, camera)
     intersects = raycaster.intersectObjects(objectToTest)

     
     if(intersects.length)
     {        
        if(!currentIntersect)
         {  
            document.body.style.cursor = "pointer"
            
        }
        currentIntersect = intersects[0]
     }
     else
     {
        document.body.style.cursor = "cursor"
        if(currentIntersect)
        {   
         //  console.log('mouse leave')
           document.body.style.cursor = "auto"
        }
         currentIntersect = null
     }
 
     // Render
     renderer.render(scene, camera)
 
     // Call tick again on the next frame
     window.requestAnimationFrame(tick)
 }
 
tick()


    
